import React from "react"
import { Link } from "gatsby"
import Layout from "components/Layout"
import Container from "components/Container"
import styles from "./NotFound.module.css"

// eslint-disable-next-line
export const NotFound = (props) => (
  <Layout {...props} seo={{ title: "404 | OpsGuru", description: "404 | OpsGuru" }}>
    <Container className={styles.root}>
      <div>
        <h1>We couldn't find the requested page. Sorry</h1>
        <Link to="/">Go to main page</Link>
      </div>
    </Container>
  </Layout>
)

export default NotFound
